import React from "react";
import { Container } from "react-bootstrap";

const NotFound = () => {
  return (
    <Container className="desktopView">
      Sidan du letade efter hittades inte
    </Container>
  );
};

export default NotFound;
